<template>
  <div class="wechat-config" v-loading="loading">
    <reminder style="margin-bottom: 20px">
      <el-row>
        <el-col :span="23">
          <span>·</span>
          <div>
            如需使用小程序支付请先前往
            <a
              class="reminder-link"
              href="https://mp.weixin.qq.com/"
              target="_blank"
              >微信公众平台</a
            >
            -> 登录小程序 -> 功能 -> 开通微信支付
            <!-- <a href="https://pay.weixin.qq.com/index.php/core/home" target="_blank">查看小程序开通微信支付指引</a> -->
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="23">
          <span>·</span>
          <div>
            如需使用H5支付请先
            <router-link
              class="reminder-link"
              :to="{ name: 'WechatPublicConfig' }"
              target="_blank"
              >填写微信公众号配置</router-link
            >
            以及前往
            <a
              class="reminder-link"
              href="https://pay.weixin.qq.com/index.php/core/home"
              target="_blank"
              >微信商户平台</a
            >
            -> 登录商户号 -> 产品中心 -> 开通H5支付
            <!-- <a href="https://pay.weixin.qq.com/index.php/core/home" target="_blank">查看小程序开通微信支付指引</a> -->
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="23">
          <span>·</span>
          <div>
            <a
              class="reminder-link"
              href="https://kf.qq.com/faq/140225MveaUz1504092YFjeM.html"
              target="_blank"
              >查看微信商户类目</a
            >
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="23">
          <span>·</span>
          <div>
            <a
              class="reminder-link"
              href="https://kf.qq.com/faq/140225MveaUz1501077rEfqI.html"
              target="_blank"
              >查看微信商户结算规则、费率、周期说明</a
            >
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="23">
          <span>·</span>
          <div>
            <a
              class="reminder-link"
              href="https://www.yuque.com/ax101w/yz6xgk/dfvy2k"
              target="_blank"
              >如何获取API证书</a
            >
          </div>
        </el-col>
      </el-row>
    </reminder>

    <div class="config-section-title">微信支付配置</div>
    <el-button class="top-btn" size="small" type="primary" @click="addItem"
      >添加</el-button
    >
    <el-table class="thead-light" :data="table" style="margin-top: 20px">
      <el-table-column label="功能" prop="features">
        <template slot-scope="{ row }">
          <template v-for="(f, fI) in row.features">
            {{
              features.find((el) => el.value === f)
                ? features.find((el) => el.value === f).label
                : ''
            }}<template v-if="fI !== row.features.length - 1">、</template>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="商户号" prop="mch_id"></el-table-column>
      <el-table-column fixed="right" label="操作" width="120px">
        <template slot-scope="{ row, $index }">
          <el-button-group>
            <el-button type="text" @click="viewItem(row, $index)"
              >编辑</el-button
            >
            <!--            <el-button type="text">编辑</el-button>-->
            <el-button type="text" @click="removeItem(row, $index)"
              >删除</el-button
            >
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      v-if="inputDialog.show"
      class="dialog-vertical"
      width="450px"
      :visible="inputDialog.show"
      :title="inputDialog.title"
      @close="close"
    >
      <el-form
        ref="inputDialog"
        class="small-form"
        size="medium"
        :model="inputDialog.data"
        :rules="inputDialog.rule"
        :label-width="inputDialog.isV3 ? '115px' : '105px'"
        @submit.native.prevent
      >
        <el-form-item
          :label="!inputDialog.isV3 ? '商户API秘钥：' : '商户APIv3秘钥：'"
          prop="app_secret"
        >
          <el-input
            v-model="inputDialog.data.app_secret"
            v-focus
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" @click="close">取消</el-button>
        <el-button size="small" type="primary" @click="confirm">确定</el-button>
      </template>
    </el-dialog>

    <el-dialog
      v-if="addDialog.show"
      class="dialog-vertical"
      :title="addDialog.title"
      :visible="addDialog.show"
      @close="addDialog.show = false"
    >
      <el-form
        size="medium"
        :model="addDialog.data"
        :rules="addDialog.rule"
        label-width="120px"
        label-position="right"
        class="small-form"
        ref="addForm"
      >
        <el-form-item label="功能：" prop="features">
          <el-checkbox v-model="checkAll" @change="onChangeAll"
            >全选/全不选</el-checkbox
          >
          <!-- <el-select v-model="addDialog.data.features" multiple>
            <el-option
              v-for="f in features"
              :key="f.value"
              :label="f.label"
              :value="f.value"
            ></el-option>
          </el-select> -->
          <el-checkbox-group v-model="addDialog.data.features">
            <el-checkbox
              v-for="f in features"
              :key="f.value"
              :label="f.value"
              >{{ f.label }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="商户号：" prop="mch_id">
          <el-input
            v-model="addDialog.data.mch_id"
            placeholder="请输入商户号"
          ></el-input>
          <div class="link">
            <i class="el-icon-info"></i>
            <span style="margin-left: 6px"
              >微信支付商户平台 -> 账户中心 -> 个人信息 -> 登录账号</span
            >
          </div>
        </el-form-item>
        <el-form-item label="商户APIv3密钥：" prop="is_set_api_key_v3">
          <template v-if="addDialog.data.is_set_api_key_v3">
            <span style="margin-right: 10px">已填写</span
            ><el-button type="text" @click="showInputDialog('修改信息', true)"
              >修改</el-button
            >
          </template>
          <el-button
            v-else
            type="text"
            @click="showInputDialog('填写信息', true)"
            >填写</el-button
          >
          <div class="link">
            <i class="el-icon-info"></i>
            <span style="margin-left: 6px"
              >微信支付商户平台 -> 账户中心 -> API安全 -> 设置APIv3密钥</span
            >
          </div>
        </el-form-item>
        <el-form-item label="API证书：" prop="refund_cert">
          <UploadFileButton
            :uploaded-text-name="
              addDialog.data.refund_cert ? `apiclient_cert.pem` : ''
            "
            button-text="请上传apiclient_cert.pem文件"
            @update:textName="addDialog.data.refund_cert = $event"
          />
        </el-form-item>
        <el-form-item label="API证书key：" prop="refund_cert_key">
          <UploadFileButton
            :uploaded-text-name="
              addDialog.data.refund_cert_key ? `apiclient_key.pem` : ''
            "
            button-text="请上传apiclient_key.pem文件"
            @update:textName="addDialog.data.refund_cert_key = $event"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" @click="addDialog.show = false">取消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="confirmAdd"
          :loading="addDialog.saveLoading"
          >{{ '保存' }}</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
import FixedActionBar from '../../../base/layout/FixedActionBar'
import UploadFileButton from '../components/UploadFileButton.vue'
import Reminder from '@/modules/common/components/Reminder'
import { rsaEncrypt } from '@/base/utils/tool'
import ListLayout from '../../../base/layout/ListLayout'
import {
  getWechatPayFeatures,
  getWechatPayList,
  removeWechatPayItem,
  setWechatPayItem,
} from '../api/wechat-config'

export default {
  components: { ListLayout, Reminder, FixedActionBar, UploadFileButton },
  data() {
    return {
      loading: true,

      table: [],

      status: false, //上传状态
      uploadIndex: -1,
      percentage: 0, //上传进度条
      proError: false, //上传出错
      inputDialog: {
        show: false,
        loading: false,
        title: '',
        data: {
          app_secret: '',
        },
        rule: {
          app_secret: [
            { required: true, message: '请输入商户API秘钥', trigger: 'blur' },
          ],
        },
        isV3: false,
      },
      addDialog: {
        show: false,
        data: null,
        title: '',
        saveLoading: false,
        loading: false,
        rule: {
          features: [
            {
              required: true,
              trigger: 'change',
              message: '请选择功能',
            },
            {
              validator: (rule, value, callback) => {
                if (value.length === 0) {
                  callback(new Error('请选择功能'))
                } else {
                  callback()
                }
              },
              trigger: 'change',
            },
          ],
          mch_id: [
            { required: true, message: '请输入商户号', trigger: 'blur' },
          ],
          is_set_api_key: [
            { required: true, message: '请输入商户API密钥', trigger: 'blur' },
          ],
          refund_cert: [
            { required: true, message: '请上传退款证书', trigger: 'change' },
          ],
          refund_cert_key: [
            { required: true, message: '请上传退款证书key', trigger: 'change' },
          ],
          is_set_api_key_v3: [
            { required: true, message: '请输入商户APIv3密钥', trigger: 'blur' },
            {
              validator: (rule, value, callback) => {
                if (value === 0) {
                  callback(new Error('请输入商户APIv3密钥'))
                } else {
                  callback()
                }
              },
              trigger: 'change',
            },
          ],
        },
      },
      features: [],

      checkAll: false,
    }
  },
  created() {
    this.$store
      .dispatch('GET_PUB_KEY')
      .then((key) => {
        // console.log(key)
      })
      .catch((err) => {
        console.log(err)
      })

    getWechatPayFeatures()
      .then(({ data, msg }) => {
        this.features = data
      })
      .catch(() => {})

    this.getList()
  },
  methods: {
    onChangeAll(e) {
      if (e) {
        // checked
        this.addDialog.data.features = []
        this.addDialog.data.features.push(...this.features.map((f) => f.value))
      } else {
        // unchecked
        this.addDialog.data.features = []
      }
    },
    getList() {
      getWechatPayList()
        .then(({ data, msg }) => {
          this.table = data
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    addItem() {
      this.addDialog.show = true
      this.addDialog.data = {
        id: '',
        mch_id: '',
        is_set_api_key_v3: 0,
        refund_cert: '',
        refund_cert_key: '',
        platform_cert: '',
        api_key_v3: '',
        features: [],
      }
      this.addDialog.title = '新增配置'
    },
    viewItem(row, rowIndex) {
      this.addDialog.show = true
      this.addDialog.data = JSON.parse(JSON.stringify(row))
      this.addDialog.title = '编辑配置'
    },
    removeItem(row, rowIndex) {
      this.$confirm('是否删除该配置', '提示').then(() => {
        removeWechatPayItem(row.id)
          .then(({ data, msg }) => {
            this.$message.success(msg)
          })
          .catch(() => {})
        this.table.splice(rowIndex, 1)
      })
    },
    confirmAdd(e) {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.addDialog.saveLoading = true
          setWechatPayItem({
            ...this.addDialog.data,
          })
            .then(({ data, msg }) => {
              this.$message.success(msg)
              this.getList()
              this.addDialog.saveLoading = false
              this.addDialog.show = false
            })
            .catch(() => {
              this.addDialog.saveLoading = false
            })
        }
      })
    },
    showInputDialog(title, isV3) {
      this.inputDialog.title = title
      this.inputDialog.isV3 = isV3
      this.inputDialog.show = true
    },
    confirm() {
      this.$refs.inputDialog.validate((valid) => {
        if (valid) {
          if (this.inputDialog.isV3) {
            this.addDialog.data.api_key_v3 = rsaEncrypt(
              this.inputDialog.data.app_secret
            )

            console.log(
              rsaEncrypt(this.inputDialog.data.app_secret),
              this.inputDialog.data.app_secret
            )
            this.addDialog.data.is_set_api_key_v3 = 1
          } else {
            this.addDialog.data.api_key = this.inputDialog.data.app_secret
            this.addDialog.data.is_set_api_key = 1
          }
          this.close()
        }
        console.log(valid)
      })
    },
    close() {
      this.inputDialog.show = false
      this.inputDialog.data.app_secret = ''
    },
    saveConfig(keyWords) {
      this.$refs[keyWords].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$request('/admin/admin/Settings/setAdminWechatConfig', {
            config: this.config,
          })
            .then((res) => {
              this.$message.success(res.msg)
              this.loading = false
            })
            .catch((err) => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wechat-config {
  .title-label {
    margin-bottom: 20px;
  }

  .el-icon-upload2 {
    margin-right: 5px;
  }

  .medium-form {
    max-width: 600px;
  }

  .link {
    color: $placeholder;

    a {
      text-decoration: none;
      color: $placeholder;
      margin-left: 5px;

      &:active {
        color: unset;
      }
    }
  }
}
</style>
