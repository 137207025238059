import upload from '@/base/utils/upload4'
import api from '@/base/utils/request'

export const uploadFile = (data) => {
  return upload({
    url: '/admin/admin/media/UploadFile',
    data,
    typeArr: ['file'],
  })
}

export const getWechatPayFeatures = (data) => {
  return api({
    url: '/admin/admin/settings/getAdminWechatFeatures',
    method: 'post',
    data,
  })
}

export const getWechatPayList = (data) => {
  return api({
    url: '/admin/admin/settings/getAdminWechatConfig',
    method: 'post',
    data,
  })
}

export const removeWechatPayItem = (id) => {
  return api({
    url: '/admin/admin/settings/delAdminWechatConfig',
    method: 'post',
    data: {
      id,
    },
  })
}

export const setWechatPayItem = (data) => {
  return api({url:'/admin/admin/settings/setAdminWechatConfig', method: 'post', data})
}